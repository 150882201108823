import React, { useRef } from "react";
import { ContentCopy } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";

export default function CopyText({ children }: any) {

  const [copied, setCopied] = React.useState(false)
  const textToCopy: any = useRef(null);

  const styles ={
    backgroundColor : !copied ? "primary" : "secondary"
  }

  function copyText() {
    navigator.clipboard.writeText(textToCopy.current.innerText);
    setCopied(true)
  }
  return (
    <Grid container flexDirection={"row"}>
      <Grid item md={12}>
        <Typography variant="h6" ref={textToCopy}>
          {children}
          <Tooltip title="Copy">
            <ContentCopy color="primary" style={{paddingLeft: 10, color: styles.backgroundColor}}onClick={copyText}></ContentCopy>
          </Tooltip>
        </Typography>
      </Grid>
    </Grid>
  );
}
