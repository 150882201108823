import * as React from "react";
import { NavBar } from "./components/navBar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Bio } from "./components/bio";
import { Skill } from "./components/skill";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Apps } from "./components/apps";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const App = () => {
  
  
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Router>
        <Route>
          <Redirect to="/Bio" />
          <NavBar />
        </Route>
        <Switch>
          <Route path="/Bio">
            <Bio />
          </Route>
          <Route path="/Skill">
            <Skill />
          </Route>
          <Route path="/Apps">
            <Apps />
          </Route>
        </Switch>
        {/* <Footer /> */}
      </Router>
    </Container>
  );
};

export default App;
