import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

export default function ToDo() {
  const [todo, setTodo] = React.useState<any>([
    
  ]);
  const [done, setDone] = React.useState<any>([
   
  ]);

  const [toDoinput, setToDoInput] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function handleChange(e: any) {
    setToDoInput(e.target.value);
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    if (toDoinput.length > 0) {
      setTodo([
        ...todo,
        {
          name: toDoinput,
        },
      ]);
    }

    setToDoInput([]);
  }

  function moveToDone(index: number) {

    const temp = todo.splice(index, 1);

    setDone([
      ...done,
      {
        name: temp[0].name,
        status: "done",
      },
    ]);
  }

  function moveTotodo(index: number){

    const temp = done.splice(index, 1);

    setTodo([
      ...todo,
      {
        name: temp[0].name,
        status: "to-do",
      },
    ]);

  }

  useEffect(() => {});

  if (loading) {
    return <h1> loading</h1>;
  } else {
    return (
      <div>
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
              {todo.map((task: any, index: number) => (
                <Card style={{ margin: 4 }} key={index}>
                  <CardContent>
                    <Typography>{task.name}</Typography>
                    <CardActionArea>
                      <CardActions style={{justifyContent: "right"}}
                        onClick={() => moveToDone(index)}
                       >
                        <Button
                          variant="contained"
                          style={{ color: "secondary" }}
                        >
                          done
                        </Button>
                      </CardActions>
                    </CardActionArea>
                  </CardContent>
                </Card>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
              {done.map((task: any, index: number) => (
                <Card style={{ margin: 4 }} key={index}>
                  <CardContent>
                    <Grid container direction={"row"}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                        <Typography>{task.name}</Typography>
                      </Grid>
                      <CardActionArea style={{justifyContent: "right"}}>
                        <CardActions  onClick={() => moveTotodo(index)} style={{justifyContent: "right"}}>
                          <Button variant="contained">to-do</Button>
                        </CardActions>
                      </CardActionArea>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            <Grid md={12} item alignContent={"center"}>
              <form onSubmit={handleSubmit}>
                <TextField
                  style={{ padding: 2 }}
                  variant="outlined"
                  type="text"
                  value={toDoinput}
                  onChange={handleChange}
                ></TextField>
                <Button
                  style={{ marginTop: 9, marginLeft: 3 }}
                  variant="contained"
                  type="submit"
                >
                  Add to do
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
