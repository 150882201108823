import React from "react";
import {
  Grid,
  Avatar,
  Typography,
  CardContent,
  Card,
} from "@mui/material";
import { SimplePage } from "../simplePage";
import { connect } from "react-redux";
import { CopyText } from "../copyContent";

function Bio({ profiles }: any) {
  return (
    <Grid container>
      <Grid item md={4}>
        <Avatar
          sx={{ height: "300px", width: "300px" }}
          alt="profile_picture"
          src={profiles[0].profilePicture}
        />
      </Grid>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <Typography>Naam : Sami Yimam</Typography>
            <Typography>Leeftijd: 43</Typography>
            <Typography>React developer</Typography>
            <Typography>Woonplaats: Utrecht</Typography>
           
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <Typography variant="h6">
          {profiles[0].bio}
          Hoi, leuk dat je mijn website bezoekt. Ik heet <strong>Sami</strong>,
          een zelfstandig webdeveloper(frontend) uit <strong>Utrecht</strong>.
          Ik ontwikkel frontend applicaties in vooral <strong>React</strong> en
          heb ook verstand van backend. Na mijn studie industriële
          automatisering heb ik voor meerdere bedrijven als software engineer
          gewerkt. Sinds twee jaar werk ik als softwareontwikkelaar voor het
          web. Ben je opzoek naar een React ontwikkelaar? dan ben je bij mij aan
          het juiste adres. Je kan mij bereiken door een bericht te sturen naar
          het mailadres hieronder.
          <CopyText>
            <strong>sami.yimam@gmail.com</strong>
          </CopyText>
        </Typography>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => {
  return { profiles: state.profiles };
};

export default SimplePage(connect(mapStateToProps)(Bio), "Bio");
