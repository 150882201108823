import * as React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

export default function MobileNavigation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isMobile) {
    return (
      <>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleMenu}
        >
          <MenuIcon />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Button component={RouterLink} to="Bio">
              Bio
            </Button>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Button component={RouterLink} to="/Skill">
              Skills
            </Button>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Button component={RouterLink} to="/Apps"> Apps</Button>
          </MenuItem>

          {/* <MenuItem onClick={handleClose}>
            <Typography variant="h6">Blog</Typography>
          </MenuItem> */}
        </Menu>
      </>
    );
  } else {
    return <></>;
  }
}
