import React from "react";
import {Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "10px",
  },
}));

function SimplePage(Component: any, title: string) {
  class NewComponent extends React.Component {
    render() {
      return (
          <Container>
            <Grid container>
              <Grid item sm={12} paddingTop="20px"> 
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Component />
            </Grid>
          </Container>
      );
    }
  }
  return NewComponent;
}

export default SimplePage;
