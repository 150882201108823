import React from "react";
import { Grid, Card, Typography, CardContent } from "@mui/material";
import { SimplePage } from "../simplePage";
import useStyles from "./Skill.style";
import { Margin } from "@mui/icons-material";

const skill = [
  {
    title: "React",
  },
  {
    title: "State management met Redux",
  },
  {
    title: "UI met material-ui @mui",
  },
  {
    title: "Typing met typeScript",
  },
  {
    title: "Version control met Git",
  },
  {
    title: "Libraries zoals formik, clsx etc",
  },
  {
    title: "Scrum",
  },
];

function Skill() {
  return (
    <Grid container maxWidth="md">
      {skill.map((item, key) => {
        return (
          <Grid  item xs={12} sm={12} md={12} lg={6} xl={6} key={key}>
            <Card raised={false} style={{ margin: 5 }}>
              <CardContent style={{ padding: 16}}>
                <Typography variant="h6" paragraph>
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default SimplePage(Skill, "Skills");
