import { makeStyles, createStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect } from "react";
import profileType from "./profile.interface";
import {connect} from "react-redux"
import { Box } from "@mui/system";

const useStyle = makeStyles({
  profilePic: {
    borderSpacing: 8
  }
})


const Profile = ({profiles}: any) => {

  const classes = useStyle()
  

  return (
   <Box className={classes.profilePic}>
      {profiles.map((profile: any, id: any) => {
        return <Avatar key={id} alt={profile.name} src={profile.profilePicture} />;
      })}
   </Box>
  );
};

const mapStateToProps = (state : any) => {
  return { profiles: state.profiles }
}

export default connect(mapStateToProps)(Profile)

