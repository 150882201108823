import { createStore, compose } from "redux";



const initialState ={
    profiles :[
       {
           "id" : 1,
           "name" : "sami",
           "profilePicture": "images/profile_picture.jpg",
           "bio" : "",
           "skills" :[
               "programming front end with react",
               "programming front end with react"
           ],
           "apps":[

           ],
           "blogs":[

           ]
       }
    ]
 
}

interface payloadType{
    id: number;
    title : string
}

interface actionType {
    type: string;
    payload : payloadType
}

const action : actionType = {
    type: "ADD_POST",
    payload :{
        id: 1, title : "Redux Tutorial 2019"
    }
}

const reducer = (state = initialState , action : actionType ) =>{
  
    return state;

}

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
  }
  
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(reducer, composeEnhancers());

export default store