import React, { ReactElement } from "react";
import { AppBar, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Profile } from "../profile";
import { Navigation } from "../navigation";
import { MobileNavigation } from "../mobileNavigation";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  profile:{
    flexGrow: 1,
    padding: "10px"
  }
})



export default function NavBar(): ReactElement {

  const classes = useStyle()
  return (
    <>
      <Box>
        <AppBar position="static" style={{boxShadow :"none"}}>
          <Toolbar>
            <MobileNavigation />
            <Typography variant="h6">
              Anne Softwares
            </Typography>
            <Box className={classes.profile}>
              <Profile />
            </Box>
            <Navigation></Navigation>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
