import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Button, Box } from "@mui/material";

/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link as RouterLink } from "react-router-dom";

export default function Navigation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) {
    return <> </>;
  } else {
    return (
      <>
        <Box>
          <Button color="inherit" component={RouterLink} to="bio">
            Bio
          </Button>
        </Box>
        <Box>
          <Button color="inherit" component={RouterLink} to="/skill">
            Skills
          </Button>
        </Box>

        <Button color="inherit" component={RouterLink} to="/apps"> Apps </Button>
        {/* <Button color="inherit"> Blog </Button> */}
      </>
    );
  }
}
