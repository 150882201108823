import { orange, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette:{
    mode : 'light',
    primary:{
      main: orange [500]
    },
    secondary : {
      main : blue [500]
    },
  },
  typography :{
    fontSize: 14
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});


export default theme;