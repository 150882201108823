import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

function People() {
  const [people, setPeople] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);
  const [show, setShow] = React.useState(false);
  const [current, setCurrent] = React.useState(1);

  useEffect(() => {
    setLoading(true);
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((data) => setPeople(data));
    setLoading(false);
  });

  const showDetails = (index: number) => {
    setShow(true);
    setCurrent(index)
  };

  if (loading) {
    return <div>loading</div>;
  } else {
    return (
      <Grid container direction={"row"}>
        {people.map((person: any, key: number) => (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card style={{ margin: 4 }} key={key}>
              <Typography>
                <CardContent style={{ padding: 9 }}>Name : {person.name}</CardContent>
                {show && (key == current) && (
                    <Typography>
                      <CardContent>username : {person.username}</CardContent>
                      <CardContent>email: {person.email}</CardContent>
                      <CardContent>streeet:{person.address.street}</CardContent>
                      <CardContent>suite: {person.address.suite}</CardContent>
                      <CardContent>city: {person.address.city}</CardContent>
                      <CardContent>zipcode :{person.address.zipcode}</CardContent>
                      <CardContent>lat: {person.address.geo.lat}</CardContent>
                      <CardContent>lng: {person.address.geo.lng}</CardContent>
                    </Typography>
                )}
                <CardActionArea>
                  <CardActions  >
                    <Button onClick={() => showDetails(key)} color="primary" style={{textTransform: 'none'}}>show details</Button>
                  </CardActions>
                </CardActionArea>
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default People;
